import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        strict: true,
        current_profile: localStorage.getItem('current_profile') || false,
        src_list: [],
        selectedAuto: false,
        activeHistory: false,
        historyRoute: false,
        historyData: [],
        bus_error: false,
        phoneNumber: '',
        gon: null,
    },
    getters: {
        routes_in_current_porfile: state => {
            return localStorage['profile_' + state.current_profile] ? localStorage.getItem('profile_' + state.current_profile).split(',') : []
        }
    },
    mutations: {
        setTrueBussError(state) {
            state.bus_error = true;
            setTimeout(function() { state.bus_error = false }, 10000);
        },
        updateCurrentProfileAndRoutes(state, { profile, routes }) {
            state.current_profile = profile;
            localStorage.setItem('profile_' + profile, routes);
            localStorage.setItem('current_profile', profile);
        },
        changeAbout(state, value) {
            state.selectedAuto = value;
            if (!value) {
                state.activeHistory = state.historyRoute = false;
                state.historyData = [];
            };
        },
        activeHistory(state, { activeHistory, historyRoute }) {
            state.activeHistory = activeHistory;
            state.historyRoute = historyRoute;
        },
        setHistoryData(state, value) {
            state.historyData = value;
        },
        setPhoneNumber(state, value) {
            state.phoneNumber = value
        },
        setGon(state, value) {
            state.gon = value.region
            state.src_list = value.region.transport
        },
    }
});