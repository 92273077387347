<template>
    <div id="about-block">
        <div id="auto" v-if="!active_history">
            Маршрут: {{auto.name}}; Номер: {{auto.num}}; Скорость: {{auto.speed ? auto.speed : 0}}км/ч;
            {{phoneNumber && 'АТП:'}} 
            <a class="usual-link" v-b-modal.phone-modal>{{phoneNumber}}</a>
        </div>
        <Hist :selected_auto="auto" v-else />
        <b-button variant="primary" @click="setActiveHistory">{{active_history ? 'Назад' : 'История' }}</b-button>
        <b-modal id="phone-modal" hide-header hide-footer>
            <h3>Забыли что-то в транспорте?</h3>
            <h4>Свяжитись с водителем через оператора автотранспортного предприятия</h4>
            <h4>Позвоните по номеру 
                <a class="usual-link" id="atp-phone-number" :href="'tel:' + phoneNumber">{{phoneNumber}}</a> 
                и назовите номер транспорта 
                <b>{{auto.num}}</b>
            </h4>
        </b-modal>
        <b-toast id="copied-notify" title="Успешно" toaster="b-toaster-bottom-center" auto-hide-delay="2500">
            Номер скопирован в буфер обмена
        </b-toast>
    </div>
</template>
<script>
import Hist from '../route_history/history.vue';

export default {
    props: {
        selected_auto: {
            type: Object,
            required: true
        }
    },
    computed: {
        auto: function() {
            return this.selected_auto;
        },
        active_history: function() {
            return this.$store.state.activeHistory
        },
        phoneNumber() {
            return this.$store.state.phoneNumber
        }
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == "updateCurrentProfileAndRoutes") {
                this.$store.commit('changeAbout', false);
            };
        });
    },
    methods: {
        setActiveHistory() {
            let activeHistory = !this.active_history,
                historyRoute = activeHistory ? this.selected_auto.type + '_' + this.selected_auto.name : false;
            this.$store.commit('activeHistory', { activeHistory, historyRoute })
        },
    },
    components: { Hist }
}
</script>

<style lang="scss">
#about-block {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    #auto {
        flex-grow: 1;
        margin: auto;
    }

    .btn {
        margin: auto;
    }

    

    #phone-modal {
        .atp-phone {
            color: #0056b3;
            cursor: pointer;
        }

        .atp-phone:hover {
            text-decoration: underline;
        }
    }
}
.usual-link {
    color: #0056b3 !important;
    cursor: pointer !important;
}

.usual-link:hover {
    text-decoration: underline !important;
}
</style>