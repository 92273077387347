<template>
    <div id="app" class="rows">
        <header>
            <Routes />
            <CurrentRoutes />
            <SiteInfo />
        </header>
        <div id="content" >
            <YMap v-if="mapType === 'yandex_map'"/>
            <LMap v-else/>
        </div>
        <footer>
            <NewsNotices v-if="!auto" />
            <About v-else :selected_auto="auto" />
        </footer>
    </div>
</template>
<script>
import Routes from './components/routes/routes.vue'
import CurrentRoutes from './components/currentRoutes/currentRoutes.vue'
import SiteInfo from './components/siteInfo/siteinfo.vue'
import YMap from './components/yandex_map/map.vue'
import LMap from './components/leaflet_map/map.vue'
import NewsNotices from './components/news_and_notices/news_and_notices.vue'
import About from './components/about/about.vue'

export default {
    beforeMount() {
        this.$store.commit('setGon', window.gon)
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == 'changeAbout') {
                if(state.selectedAuto.type && state.selectedAuto.name) {
                    this.$http
                        .get(`/get_phone?route=${state.selectedAuto.type}_${state.selectedAuto.name}`)
                        .then(resp => {
                            if (!resp.data.message) {
                                this.$store.commit('setPhoneNumber', resp.data)
                            }
                        })
                }
            }
        });
    },
    computed: {
        auto: function() {
            return this.$store.state.selectedAuto
        },
        mapType() {
            return this.$store.state.gon.map_type
        }
    },
    components: { 
        Routes, 
        CurrentRoutes, 
        SiteInfo, 
        YMap, 
        LMap, 
        NewsNotices, 
        About }
}
</script>