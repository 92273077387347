<template>
    <div id="history-block">
        <PulseLoader id='history-loader' :loading="historyLoading" :color="'grey'"/>
        <div id=history-info>История маршрута №{{route}} на {{ curr_time }} 
            <a v-b-modal.phone-modal class="usual-link">Связаться с водителем</a>
        </div>
        <div id="slider-block">
            <div class="glyphicon glyphicon-menu-left" @click="stepUpDown(true)"></div>
            <div id="input-block">
                <input type="range" :max="max_range" :min="min_range" :step="step" v-model="intValue" @change="changeValue" @input="inputValue" class="slider">
                <b-button id="history-minus" pill @click="minusHistory"> - </b-button>
                <b-button id="history-plus" pill @click="plusHistory"> + </b-button>
            </div>
            <div class="glyphicon glyphicon-menu-right" @click="stepUpDown()"></div>
        </div>
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
let months = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июля',
    7: 'августа',
    8: 'сентября',
    9: 'октября',
    10: 'ноября',
    11: 'декабря'
}

export default {
    components : {
        PulseLoader
    },
    props: {
        selected_auto: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            route: this.selected_auto.name,
            max_range: 1440, // 24 часа в минутах 
            min_range: 2,
            current_value: 2,
            step: 2, // 2 минуты
            value: 1440,
            historyLoading: false,
        }
    },
    mounted() {
        this.getHistory();
    },
    computed: {
        curr_time() {
            let date = new Date(Date.now() - 1000 * 60 * this.current_value);
            return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.toLocaleTimeString().substring(0, 5);
        },
        intValue: {
            get: function() {
                return parseInt(this.value);
            },
            set: function(newValue) {
                this.value = parseInt(newValue);
            }
        }
    },
    methods: {
        inputValue(){
            if (this.intValue == this.max_range) return this.current_value = this.step;
            if (this.intValue == this.min_range) return this.current_value = this.max_range
            this.current_value = this.max_range - this.intValue + this.step;
        },
        getHistory(route) {
            this.historyLoading = true
            this.$http.get('/history', {
                params: {
                    route_names: this.$store.state.historyRoute,
                    time: new Date(Date.now() - 1000 * 60 * this.current_value)
                }
            }).then(response => {
                this.$store.commit('setHistoryData', response.data);
            }).finally(() => {
                this.historyLoading = false
            });
        },
        changeValue() {
            this.getHistory();
        },
        stepUpDown(isDown = false) {
            if (isDown && this.intValue == this.min_range) return false;
            if (!isDown && this.intValue == this.max_range) return false;
            isDown ? this.intValue -= this.step : this.intValue += this.step;
            this.changeValue();
        },
        minusHistory() {
            if (this.value === this.min_range) return
            this.current_value = this.current_value + this.step;
            this.value = this.value - this.step;
            this.getHistory();
        },
        plusHistory() {
            if (this.value === this.max_range) return
            this.current_value = this.current_value - this.step;
            this.value = this.value + this.step;
            this.getHistory();
        }
    }
};
</script>
<style lang="scss">
#history-block {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    flex-grow: 1;

    #history-loader {
        position: fixed;
        bottom: 30px;
        left: 30px;
    }

    #history-info {
        font-weight: bold;
        padding-bottom: 10px;
        text-align: center;
    }

    #slider-block {
        width: 100%;
        padding-right: 10px;
        display: flex;

        #input-block {
            width: 100%;
            display: flex;
        }

        #history-minus {
            margin-left: 8px;
            height: 30px;
            width: 30px;
            font-weight: 700;
        }

        #history-plus {
            margin-left: 4px;
            height: 30px;
            width: 30px;
            font-weight: 700;
        }

        .slider {
            -webkit-appearance: none;
            width: 100%;
            margin: auto;
            height: 15px;
            border-radius: 5px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            -webkit-transition: .2s;
            transition: opacity .2s;
            vertical-align: middle;

            &::hover {
                opacity: 1;
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: #4CAF50;
                cursor: pointer;
            }

            &::-moz-range-thumb {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: #4CAF50;
                cursor: pointer;
            }
        }

        .glyphicon {
            width: 25px;
            margin: 0;
            padding: 0;
            font-size: 15px;
            cursor: pointer;
        }
    }
}
</style>