<template>
    <div id="current-routes">
        <div v-for="route in selected_routes" v-if="route.routes && route.routes.length>0" class="buses">
            <img :src="route.img"><span>{{route.routes.toLocaleString()}}</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selected_routes: this.parseCurrentRoutes(this.$store.getters.routes_in_current_porfile),
            src_list: this.$store.state.src_list
        }
    },
    methods: {
        parseCurrentRoutes(routes) {
            let _this = this,
                shadow_src_list = this.$lodash.cloneDeep(this.$store.state.src_list);
            this.$lodash.each(shadow_src_list, function(src) {
                _this.$lodash.each(routes, function(route) {
                    if (src.id == route.split('_')[0]) {
                        if (src['routes']) {
                            src['routes'].push(route.split('_')[1]);
                        } else {
                            src['routes'] = [route.split('_')[1]];
                        }
                    };
                });
            });
            return shadow_src_list;
        }
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == "updateCurrentProfileAndRoutes") this.selected_routes = this.parseCurrentRoutes(mutation.payload.routes);
        });
    }
}
</script>
<style lang="scss">
#current-routes {
    flex-grow: 1;
    display: flex;
    font-size: 14px;

    .buses {
        display: flex;
        margin-left: 5px;
        font-weight: bold;

        img {
            margin: auto;
        }

        span {
            margin: auto;
        }
    }
}
</style>