<template>
    <div v-if="error" class="alert alert-danger">ВНИМАНИЕ! Наблюдаются проблемы с официальным сайтом {{siteName}}, в связи с чем получение актуального положения автобусов может быть затруднено. Мы сохранили для вас последнее местоположение некоторых автобусов и скрестили пальцы в ожидании скорого восстановления</div>
    <div v-else-if="isEmpty" id="empty">На текущий момент новостей и уведомлений нет</div>
    <a v-else-if="current_item && current_item.info.title" :href="current_item.info.url" target="blank">{{current_item.info.title}}</a>
    <div v-else-if="current_item" v-html="current_item.info.text"></div>
</template>
<script>
export default {
    data() {
        return {
            info_list: [],
            current_item: false
        }
    },
    mounted() {
        this.getXhrInfo();
        setInterval(() => {
            this.getXhrInfo();
        }, 90000);
    },
    computed: {
        isEmpty() {
            return this.info_list.length == 0;
        },
        error() {
            return this.$store.state.bus_error
        },
        siteName() {
            return this.$store.state.gon.display_api_name
        }
    },
    methods: {
        getXhrInfo() {
            this.$http.get('/info').then(response => {
                if (this.info_list.length && !response.data.info.length) return false;
                this.info_list = response.data.info;
                this.current_item = { info: this.info_list[0], id: 0 };
                if (this.info_list.length > 2) {
                    clearInterval(this.swap_interval);
                    this.swap_interval = setInterval(() => {
                        var id = this.current_item.id + 1;
                        this.current_item = { info: this.info_list[id], id: id };
                        if (id === this.info_list.length - 1) this.current_item.id = -1;
                    }, 15000)
                }
            });
        }
    }
}
</script>
<style lang="scss">
#empty {
    width: 100%;
    height: 100%;
    padding: 5px;
    margin: 0;
    font-weight: bold;
}
</style>