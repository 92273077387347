<template>
    <div id="ymap"></div>
</template>
<script>
const marker_html = `<div class="marker">
                        {%if properties.angle %}
                            <img class="marker-row" src="arrow_{{properties.arrow_color}}.png" style="transform:rotate({{properties.angle}}deg);"/>
                        {%endif%}
                        <div class="marker-body" style="border: 2px solid {{properties.color}}">{{ properties.name }}</div>
                    </div>`;

export default {
    data() {
        return {
            currentMarkers: {},
            map: null,
            currentInterval: false,
            currentRoute: {},
            activeRoute: false,
            coordinates: {},
            moveBusInterval: false,
            subscriptions: [],
        }
    },
    mounted() {
        let _this = this;
        ymaps.ready(function() {
            _this.map = new ymaps.Map('ymap', {
                center: _this.$store.state.gon.map_center_coordinates,
                zoom: 12,
                controls: ['geolocationControl', 'trafficControl', 'typeSelector', 'zoomControl', new ymaps.control.SearchControl()]
            });
            _this.map.events.add('click', function() {
                if (_this.$store.state.activeHistory) return
                if (!_this.activeRoute) return false;
                // _this.clearMarkers();
                _this.clearActiveRoutes();
                _this.setIntervalMoveBus();
                _this.getMarkers(false);
                _this.$store.commit('changeAbout', false);
            });
            if (_this.$store.state.current_profile && _this.$store.getters.routes_in_current_porfile.length) _this.getMarkers();
            _this.$store.subscribe((mutation, state) => {
                if (mutation.type == "updateCurrentProfileAndRoutes") {
                    _this.clearMarkers();
                    if (localStorage.getItem('profile_' + mutation.payload.profile).length) {
                        _this.setIntervalMoveBus();
                        _this.getMarkers(true, mutation.payload.profile);
                        _this.clearActiveRoutes();
                    } else {
                        _this.setIntervalMoveBus(true);
                    };
                };
                if (mutation.type == "activeHistory") {
                    if (state.historyRoute) {
                        _this.setIntervalMoveBus(true);
                        _this.clearMarkers(false);
                    } else {
                        _this.clearMarkers(false);
                        _this.getMarkers(false, mutation.payload.profile);
                        _this.setIntervalMoveBus();
                    };
                };
                if (mutation.type == "setHistoryData") {
                    if (state.historyData.length) {
                        if (Object.keys(_this.currentMarkers).length) _this.clearMarkers(false);
                        _this.getMarkers(false, mutation.payload.profile, state.historyData);
                    } else {
                        _this.clearMarkers(false)
                    };
                };
            });
            _this.setIntervalMoveBus();
        });
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == 'changeAbout') {
                const markers = Object.keys(this.currentMarkers)
                markers.forEach(key => {
                    let marker = this.currentMarkers[key]
                    marker.properties.set('color', this.$lodash.find(state.src_list, { id: parseInt(marker.properties._data.type) }).color)
                })
                if (state.selectedAuto) {
                    let marker = this.currentMarkers[state.selectedAuto.type + "_" + state.selectedAuto.name + "_" + state.selectedAuto.num]
                    marker.properties.set('color', 'black')
                }
            }
        });
        this.$store.getters.routes_in_current_porfile.forEach((route) => {
            this.$cable.subscribe({
                channel: 'RouteChannel',
                route: route
            }, route)
        })
        this.subscriptions = this.$store.getters.routes_in_current_porfile
        this.createChannels()
    },
    methods: {
        setIntervalMoveBus(clear_interval = false) {
            clearInterval(this.moveBusInterval ? this.moveBusInterval : false)
            if (!clear_interval) {
                this.moveBusInterval = setInterval(this.moveBus, 500);
            };
        },
        createMarkers(data, profile, with_interval = false) {
            let _this = this;
            clearInterval(this.currentInterval ? this.currentInterval : false);
            this.$lodash.each(data, function(bus) {
                if (bus["type"] == undefined) return false;
                if (!_this.$lodash.hasIn(_this.currentMarkers, bus["type"] + "_" + bus["name"] + "_" + bus["num"])) {
                    _this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]] = _this.addMarker(bus);
                } else {
                    let color = _this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]].properties._data.color
                    if (color === 'black') return
                    _this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]].properties
                        .set('color', bus["lost"] ? 'gray' : _this.$lodash.find(_this.$store.state.src_list, { id: parseInt(bus["type"]) }).color)
                }
            });
        },
        getMarkers(check_markers = false, profile = this.$store.state.current_profile, data = false) {
            this.subscriptions.forEach((route) => {
                this.$cable.unsubscribe(route)
            })
            let _this = this,
                bus_array = localStorage.getItem('profile_' + profile).split(',');
            if (data) {
                this.createMarkers(data);
            } else {
                this.$http.post('/get_bus', { bus: bus_array }).then(response => {
                    this.coordinates = {}
                    response.data.forEach((bus) => {
                        if (!this.coordinates[`${bus.type}_${bus.name}`]) {
                            this.coordinates[`${bus.type}_${bus.name}`] = []
                        }
                        this.coordinates[`${bus.type}_${bus.name}`].push(bus)
                    })
                    if (check_markers && response.data.length) _this.checkExcludeMarkers(response.data);
                    _this.createMarkers(response.data, profile, true);
                    bus_array.forEach((route) => {
                        _this.$cable.subscribe({
                            channel: 'RouteChannel',
                            route: route
                        }, route)
                    })
                    this.subscriptions = bus_array
                    this.createChannels()
                }).catch(e => {
                    this.$store.commit('setTrueBussError');
                    setTimeout(function() { _this.getMarkers(false, profile) }, 5000);
                });
            }
        },
        checkExcludeMarkers(data) {
            if (this.$lodash.isEmpty(this.currentMarkers)) { this.clearMarkers(); return false; };
            let _this = this,
                shadow_currentMarkers = this.$lodash.cloneDeep(this.currentMarkers);
            this.$lodash.each(shadow_currentMarkers, function(item, index) {
                if (!_this.$lodash.filter(data, { type: index.split('_')[0], name: index.split('_')[1], num: index.split('_')[2] }).length) {
                    _this.map.geoObjects.remove(_this.currentMarkers[index]);
                    delete _this.currentMarkers[index];
                };
            });
        },
        clearMarkers(with_clear_routes = true) {
            this.subscriptions.forEach((route) => {
                this.$cable.unsubscribe(route)
            })
            this.subscriptions = []
            let _this = this;
            this.coordinates == {};
            clearInterval(this.currentInterval ? this.currentInterval : false);
            this.$lodash.each(this.currentMarkers, function(marker) {
                _this.map.geoObjects.remove(marker);
            });
            this.currentMarkers = {};
            if (with_clear_routes) this.clearActiveRoutes();
        },
        clearActiveRoutes() {
            if (this.activeRoute) {
                if (this.currentRoute[this.activeRoute]) {
                    this.map.geoObjects.remove(this.currentRoute[this.activeRoute][0]);
                    this.map.geoObjects.remove(this.currentRoute[this.activeRoute][1]);
                };
                this.activeRoute = false;
            };
        },
        addRouteInMap(bus) {
            this.clearActiveRoutes();
            if (this.currentRoute[bus.name]) {
                this.map.geoObjects.add(this.currentRoute[bus.name][0]);
                this.map.geoObjects.add(this.currentRoute[bus.name][1]);
            };
            this.activeRoute = bus.name;
        },
        addMarker(bus) {
            let hint = '',
                _this = this,
                lat = bus["lat"].replace(/^(\d\d)/, '$1.'),
                lon = bus["lon"].replace(/^(\d\d)/, '$1.');
            if (bus["speed"] != '') hint = "<b>Cкорость</b>: " + bus["speed"] + "км/ч; ";
            if (bus["num"] != '') hint += "<b>Номер</b>: " + bus["num"];
            let color = ''
            if (bus["lost"]) {
                color = 'grey'
            }
            if (this.$store.state.selectedAuto && (this.$store.state.selectedAuto.num === bus['num'] )) {
                color = 'black'
            }
            let myPlacemark = new ymaps.Placemark([lat, lon], {
                name: bus["name"],
                moreText: bus["name"].length > 2,
                angle: (this.$lodash.isUndefined(bus["angle"]) ? 0 : bus["angle"]),
                type: bus["type"],
                arrow_color: this.$lodash.find(this.$store.state.src_list, { id: parseInt(bus.type) }).color,
                color:  color || this.$lodash.find(this.$store.state.src_list, { id: parseInt(bus.type) }).color,
                hintContent: hint
            }, {
                iconLayout: ymaps.templateLayoutFactory.createClass(marker_html),
                iconShape: { type: 'Circle', coordinates: [0, 0], radius: 12.5 }
            });
            myPlacemark.events.add('click', function(e) {
                _this.$store.commit('changeAbout', bus);
                if (!_this.currentRoute.hasOwnProperty(bus.name)) {
                    _this.$http.get('/get_route', { params: { route: bus['type'] + '_' + bus['name'] } }).then(response => {
                        _this.$lodash.each(response.data, function(route) {
                            if (route.length) {
                                let myPolyline = new ymaps.Polyline(
                                    route, {
                                        balloonContent: bus['name']
                                    }, {
                                        strokeColor: "#ff0000",
                                        strokeWidth: 4,
                                        strokeOpacity: 0.5
                                    });
                                _this.currentRoute[bus.name] ? _this.currentRoute[bus.name].push(myPolyline) : _this.currentRoute[bus.name] = [myPolyline];
                            };
                        });
                        _this.addRouteInMap(bus);
                    });
                } else {
                    if (_this.activeRoute !== bus.name) _this.addRouteInMap(bus);
                };
            });
            this.map.geoObjects.add(myPlacemark);
            return myPlacemark;
        },
        moveBus() {
            Object.keys(this.coordinates).forEach((key) => {
                this.coordinates[key].forEach((bus) => {
                    if (bus["prev"] == undefined) return false;
                    try {
                        let marker = this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]]
                        if (!marker) return false
                        let q,
                            curLat = parseFloat(marker.geometry.getCoordinates()[0]),
                            curLon = parseFloat(marker.geometry.getCoordinates()[1]),
                            targetLat = bus["lat"].replace(/^(\d\d)/, '$1.'),
                            targetLon = bus["lon"].replace(/^(\d\d)/, '$1.'),
                            prevLat = bus["prev"]["lat"].replace(/^(\d\d)/, '$1.'),
                            prevLon = bus["prev"]["lon"].replace(/^(\d\d)/, '$1.'),
                            distCur = Math.sqrt(Math.pow(targetLat - curLat, 2) + Math.pow(targetLon - curLon, 2)),
                            distPrev = Math.sqrt(Math.pow(targetLat - prevLat, 2) + Math.pow(targetLon - prevLon, 2));
                        marker.properties.set('angle', bus["angle"] ? bus["angle"] : 0);
                        marker.properties.set('speed', bus["speed"] ? bus["speed"] : 0);
                        if (distPrev > distCur) {
                            q = distPrev / distCur
                        } else {
                            q = distCur / distPrev
                        };
                        let k = 20 / q

                        let dlat = (targetLat - curLat) / k
                        let dlon = (targetLon - curLon) / k

                        let newLat = curLat + dlat;
                        let newLon = curLon + dlon;

                        let distNew = Math.sqrt(Math.pow(targetLat - newLat, 2) + Math.pow(targetLon - newLon, 2));
                        if (distNew < distCur) {
                            marker.geometry.setCoordinates([newLat, newLon]);
                        } else {
                            marker.geometry.setCoordinates([targetLat, targetLon]);
                        };
                    } catch (e) {
                        console.log('====== Ошибка передвижения маркера', e, bus);
                    };
                });
            })
        },
        createChannels() {
            const t = this
            this.subscriptions.forEach((sub) => {
                t.$cable._addChannel(sub, {
                    rejected() {
                        console.log('rejected to connect to' + sub)
                    },
                    received(data) {
                        this.coordinates[sub] = data
                        this.createMarkers(data, '')
                    },
                }, t)
            })
        }
    },
}
</script>
<style lang="scss">
#ymap {
    width: 100%;
    height: 100%;

    .marker {
        text-align: center;
        width: 60px;
        height: 60px;
        padding: 1px;
        top: -10px;
        left: -10px;
        position: absolute;
        box-sizing: border-box;
    }

    .marker-row {
        width: 45px;
        height: 45px;
        top: -8px;
        left: -8px;
        position: absolute;
        z-index: -999;
    }

    .marker-body {
        top: 10px;
        text-align: center;
        vertical-align: center;
        padding-top: 2px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: white;
    }
}
</style>