<template>
    <div id="routes-block">
        <b-button v-b-modal.modal-routes variant="success">Маршруты</b-button>
        <b-modal id="modal-routes" title="Маршруты" no-close-on-esc hide-header-close no-close-on-backdrop>
            <template slot="modal-title">
                <h3>Маршруты</h3>
                <b-button variant="success" @click="updateCurrentProfile">Применить</b-button>
            </template>
            <div class="d-block text-center">
                <div id="controls">
                    <b-dropdown :text="'Профиль - ' + current_profile.title" variant="outline-primary">
                        <b-dropdown-item v-for="pf in profiles" :key="pf.id" @click="changeProfile(pf)">{{pf.title}}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <div id="routes">
                    <div v-if="routes.length==0" class="alert alert-warning">Маршрутов не найдено</div>
                    <div v-for="(route, index) in routes" class="rows">
                        <h4 v-if="$lodash.filter(src_list, {id: parseInt(index)}).length"><img :src="$lodash.find(src_list, {id: parseInt(index)}).img">{{$lodash.find(src_list, {id: parseInt(index)}).title}}</h4>
                        <div id="route-list">
                            <div v-for="r in route" class="bus">
                                <label :key="r" :class="{active: checkInList(index+'_'+r)}" @click="checkInput(index+'_'+r)">{{r}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template slot="modal-footer">
                <b-button variant="danger" @click="clearAll">Сбросить отметки</b-button>
                <b-button variant="success" @click="updateCurrentProfile">Применить</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
let profiles = [
    { id: 1, title: 'Работа' },
    { id: 2, title: 'Дом' },
    { id: 3, title: '1й особый' },
    { id: 4, title: '2й особый' },
    { id: 5, title: '3й особый' },
    { id: 6, title: 'Всё' }
];

export default {
    data() {
        return {
            current_profile: this.$store.state.current_profile ? this.$lodash.find(profiles, { id: parseInt(this.$store.state.current_profile) }) : profiles[0],
            profiles: profiles,
            routes: [],
            src_list: this.$store.state.src_list,
            checked_all: this.$store.state.current_profile == "6" ? this.$store.getters.routes_in_current_porfile.length == 0 : false,
            checkedRoutes: this.$store.state.current_profile ? this.$store.getters.routes_in_current_porfile : []
        }
    },
    mounted() {
        this.getBusList();
    },
    methods: {
        checkInput(value) {
            if (this.checkedRoutes.indexOf(value) >= 0) {
                let index = this.checkedRoutes.indexOf(value);
                this.checkedRoutes.splice(index, 1);
            } else {
                this.checkedRoutes.push(value);
            };
        },
        getBusList() {
            this.$http.get('/get_bus_list').then(response => { 
                this.routes = response.data;
                const urlParams = new URLSearchParams(window.location.search)
                if (urlParams.get('current_profile')) {
                    for(var param of urlParams.entries()) {
                        if (param[0].includes('profile_')) {
                            if (param[0].split('_')[1] === urlParams.get('current_profile')) {
                                this.$store.commit('updateCurrentProfileAndRoutes', {profile: param[0].split('_')[1], routes: param[1].split(' ')})
                            } else {
                                localStorage.setItem(param[0], param[1].split(' ').join(','))
                            }
                        }
                    }
                    window.location.search = ''
                }
                else if (!localStorage.getItem('current_profile')) {
                    const routes = []
                    Object.keys(response.data).forEach(key => {
                        routes.push(key + '_' + response.data[key][Math.floor(Math.random() * response.data[key].length)])
                    })
                    this.$store.commit('updateCurrentProfileAndRoutes', {profile: 1, routes: routes})
                }
            }).catch(e => {
                // В редисе не всегда есть, на момент запроса, данные по маршрутам, поэтому запрос повторяется в случае ошибки.
                setTimeout(this.getBusList, 2000);
            });
        },
        clearAll() {
            this.checked_all = false;
            this.checkedRoutes = [];
        },
        checkInList(index) {
            return this.checked_all || this.checkedRoutes.indexOf(index) >= 0
        },
        updateCurrentProfile() {
            let profile = this.current_profile.id,
                routes = this.checkedRoutes;
            this.$store.commit('updateCurrentProfileAndRoutes', { profile, routes });
            this.$bvModal.hide('modal-routes');
        },
        changeProfile(pf) {
            let _this = this;
            this.current_profile = pf;
            if (pf.id == 6) {
                this.checked_all = true;
                this.$lodash.each(this.routes, function(item, index) {
                    _this.$lodash.each(item, function(route) {
                        _this.checkedRoutes.push(index + '_' + route);
                    });
                });
            } else {
                this.checked_all = false;
                this.checkedRoutes = localStorage.getItem('profile_' + pf.id) ? localStorage.getItem('profile_' + pf.id).split(',') : [];
            };
        }
    }
}
</script>
<style lang="scss">
#modal-routes___BV_modal_outer_ {
    .modal-dialog {
        max-width: 99%;
        height: 100%;
        margin: auto;
    }

    #modal-routes___BV_modal_content_ {
        height: 100%;
    }

    #modal-routes___BV_modal_body_ {
        overflow: auto;
    }

    .modal-title {
        width: 100%;
        display: flex;

        h3 {
            flex-grow: 1;
            margin: 0;
        }
    }

    .modal-body {
        .btn-outline-primary {
            color: #333;
            background-color: #fff;
            border-color: #ccc;

            &:focus {
                box-shadow: none;
            }
        }

        #controls {
            text-align: left;

            .dropdown-item {
                font-size: 14px;
            }
        }

        #routes {
            padding-top: 20px;

            img {
                vertical-align: text-top;
                padding: 0;
                margin-right: 10px;
            }

            .rows {
                text-align: left;
            }

            #route-list {
                margin: 0;

                .bus {
                    height: 60px;
                    width: 70px;
                    float: left;
                }

                .btn-group-toggle {
                    &:focus {
                        outline: none;
                    }
                }

                label {
                    cursor: pointer;
                    text-align: center;
                    width: 40px;
                    border-radius: 5px;
                    margin: 0;
                    font-weight: bold;
                    font-size: 14px;

                    &:focus,
                    &:active {
                        outline: none;
                    }
                }

                label.active {
                    color: white;
                    background-color: rgba(0, 128, 0, 0.62);
                }

                label.focus {
                    outline: none;
                }
            }
        }
    }

    .modal-footer {
        display: block;

        .btn-success {
            float: right;
        }
    }
}
</style>