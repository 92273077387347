<template>
    <div id="siteInfo-block">
        <b-button v-b-modal.modal-siteinfo id="siteinfo_btn" variant="warning">?</b-button>
        <b-modal id="modal-siteinfo" no-close-on-esc no-close-on-backdrop hide-header hide-footer>
            <b-button @click="$bvModal.hide('modal-siteinfo')">×</b-button>
            <p style="font-size: 2em">Сайт — <a :href="siteHref" target="blank">{{siteName}}</a></p>
            <h3>О важном</h3>
            <span>Сервис создан аналитической группой "Данные общественного назначения"</span> <br />
            <a href="https://don.su" target="blank"> <img src="/img/donsu.png " style="max-width: 350px"></a>
            <a href="https://don.su" target="blank">don.su</a>
            <br>
            <p>
                Наша электронная почта - <a href="mailto:bus@don.su" target="blank">bus@don.su</a>
            </p>
            <hr>
            <p>
                <b>Легенда:</b><br>! Информация от транспорта поступает каждые 20 секунд<br><img src="/img/q_arrow.png">&nbsp;Для транспорта с датчиком скорости и направления отображается индикатор со стрелочкой.<br><img src="/img/q_circle.png">&nbsp;Для остальных - только кружок. Цвет определяет тип транспорта.</p>
            <hr>
            <p>
                Предложения слать сюда: <a href="http://bus61.reformal.ru" target="blank"><img src="/reformal.png"></a>
            </p>
            <hr>
            <div v-html="siteThanks" />
        </b-modal>
    </div>
</template>
<script>
export default {
    computed: {
        siteThanks() {
            return this.$store.state.gon.display_site_thanks
        },
        siteName() {
            return this.$store.state.gon.display_site_name
        },
        siteHref() {
            return this.$store.state.gon.display_site_href
        }
    }
}
</script>
<style lang="scss">
#modal-siteinfo___BV_modal_outer_ {

    .modal-dialog {
        max-width: 99%;
        height: 100%;
        margin: auto;
    }

    #modal-siteinfo___BV_modal_body_ {
        button {
            float: right;
            font-weight: bold;

        }
    }
}

#siteInfo-block {
    float: right;

    #siteinfo_btn {
        color: #fff;
    }
}
</style>