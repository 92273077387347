<template>
    <div id="map"></div>
</template>
<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
    data() {
        return {
            currentMarkers: {},
            map: null,
            currentInterval: false,
            currentRoute: {},
            activeRoute: false,
            coordinates: {},
            moveBusInterval: false,
            subscriptions: [],
        }
    },
    mounted() {
        let _this = this;
        this.map = L.map('map').setView(this.$store.state.gon.map_center_coordinates, 12);
        L.tileLayer('https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: 'Imagery from <a href="http://giscience.uni-hd.de/">GIScience Research Group @ University of Heidelberg</a> | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        if (this.$store.state.current_profile && this.$store.getters.routes_in_current_porfile.length) this.getMarkers();
        this.setIntervalMoveBus();

        this.map.on('click', () => {
            if (this.$store.state.activeHistory) return
            if (!this.activeRoute) return false;
            this.clearMarkers();
            this.clearActiveRoutes();
            this.setIntervalMoveBus();
            this.getMarkers(false);
            this.$store.commit('changeAbout', false);
        });
        this.map.on('zoomstart', function() {
            _this.setIntervalMoveBus(true);
        });
        this.map.on('zoomend', function() {
            if (_this.$store.state.activeHistory) return false;
            _this.setIntervalMoveBus();
        });
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == "updateCurrentProfileAndRoutes") {
                _this.clearMarkers();
                if (localStorage.getItem('profile_' + mutation.payload.profile).length) {
                    _this.setIntervalMoveBus();
                    _this.getMarkers(true, mutation.payload.profile);
                    _this.clearActiveRoutes();
                } else {
                    _this.setIntervalMoveBus(true);
                };
            };
            if (mutation.type == "activeHistory") {
                if (state.historyRoute) {
                    _this.setIntervalMoveBus(true);
                    _this.clearMarkers(false);
                } else {
                    _this.clearMarkers(false);
                    _this.getMarkers(false, mutation.payload.profile);
                    _this.setIntervalMoveBus();
                };
            };
            if (mutation.type == "setHistoryData") {
                if (state.historyData.length) {
                    if (Object.keys(_this.currentMarkers).length) _this.clearMarkers(false);
                    _this.getMarkers(false, mutation.payload.profile, state.historyData);
                } else {
                    _this.clearMarkers(false)
                };
            };
        });
    },
    methods: {
        setIntervalMoveBus(clear_interval = false) {
            clearInterval(this.moveBusInterval ? this.moveBusInterval : false)
            if (!clear_interval) {
                this.moveBusInterval = setInterval(this.moveBus, 500);
            };
        },
        createMarkers(data, profile, with_interval = false) {
            let _this = this;
            clearInterval(this.currentInterval ? this.currentInterval : false);
            this.$lodash.each(data, function(bus) {
                if (bus["type"] == undefined) return false;
                if (!_this.$lodash.hasIn(_this.currentMarkers, bus["type"] + "_" + bus["name"] + "_" + bus["num"])) {
                    _this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]] = _this.addMarker(bus);
                } else {
                    // let color = _this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]].properties._data.color
                    // if (color === 'black') return
                    // _this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]].properties
                    //     .set('color', bus["lost"] ? 'gray' : _this.$lodash.find(_this.$store.state.src_list, { id: parseInt(bus["type"]) }).color)
                }
            });
        },
        getMarkers(check_markers = false, profile = this.$store.state.current_profile, data = false) {
            this.subscriptions.forEach((route) => {
                this.$cable.unsubscribe(route)
            })
            let _this = this,
                bus_array = localStorage.getItem('profile_' + profile).split(',');
            if (data) {
                this.createMarkers(data);
            } else {
                this.$http.post('/get_bus', { bus: bus_array }).then(response => {
                    this.coordinates = {}
                    response.data.forEach((bus) => {
                        if (!this.coordinates[`${bus.type}_${bus.name}`]) {
                            this.coordinates[`${bus.type}_${bus.name}`] = []
                        }
                        this.coordinates[`${bus.type}_${bus.name}`].push(bus)
                    })
                    if (check_markers && response.data.length) _this.checkExcludeMarkers(response.data);
                    _this.createMarkers(response.data, profile, true);
                    bus_array.forEach((route) => {
                        _this.$cable.subscribe({
                            channel: 'RouteChannel',
                            route: route
                        }, route)
                    })
                    this.subscriptions = bus_array
                    this.createChannels()
                }).catch(e => {
                    this.$store.commit('setTrueBussError');
                    setTimeout(function() { _this.getMarkers(false, profile) }, 5000);
                });
            }
        },
        checkExcludeMarkers(data) {
            if (this.$lodash.isEmpty(this.currentMarkers)) { this.clearMarkers(); return false; };
            let _this = this,
                shadow_currentMarkers = this.$lodash.cloneDeep(this.currentMarkers);
            this.$lodash.each(shadow_currentMarkers, function(item, index) {
                if (!_this.$lodash.filter(data, { type: index.split('_')[0], name: index.split('_')[1], num: index.split('_')[2] }).length) {
                    _this.map.removeLayer(_this.currentMarkers[index]);
                    delete _this.currentMarkers[index];
                };
            });
        },
        clearMarkers(with_clear_routes = true) {
            this.subscriptions.forEach((route) => {
                this.$cable.unsubscribe(route)
            })
            this.subscriptions = []
            let _this = this;
            this.coordinates == {};
            clearInterval(this.currentInterval ? this.currentInterval : false);
            this.$lodash.each(this.currentMarkers, function(marker) {
                _this.map.removeLayer(marker);
            });
            this.currentMarkers = {};
            if (with_clear_routes) this.clearActiveRoutes();
        },
        clearActiveRoutes() {
            if (this.activeRoute) {
                if (this.currentRoute[this.activeRoute]) {
                    this.map.removeLayer(this.currentRoute[this.activeRoute][0]);
                    this.map.removeLayer(this.currentRoute[this.activeRoute][1]);
                };
                this.activeRoute = false;
            };
        },
        addRouteInMap(bus) {
            this.clearActiveRoutes();
            if (this.currentRoute[bus.name]) {
                this.map.addLayer(this.currentRoute[bus.name][0]);
                this.map.addLayer(this.currentRoute[bus.name][1]);
            };
            this.activeRoute = bus.name;
        },
        changeDivIcon(bus) {
            let color = this.$lodash.find(this.$store.state.src_list, { id: parseInt(bus.type) }).color;
            let arrow = bus['angle'] ? '<img class="marker-row" src="arrow_' + bus['type'] + '.png" style="transform:rotate(' + bus['angle'] + 'deg);"/>' : '';
            return L.divIcon({
                html: '<div class="marker">' + arrow + '<div class="marker-body" style="border: 2px solid ' + color + '">' + bus['name'] + '</div></div>'
            });
        },
        addMarker(bus) {
            let hint = '',
                _this = this,
                lat = bus["lat"].replace(/^(\d\d)/, '$1.'),
                lon = bus["lon"].replace(/^(\d\d)/, '$1.');
            if (bus["speed"] != '') hint = "<b>Cкорость</b>: " + bus["speed"] + "км/ч; ";
            if (bus["num"] != '') hint += "<b>Номер</b>: " + bus["num"];
            let myPlacemark = L.marker([lat, lon], { icon: this.changeDivIcon(bus), angle: bus['angle'] }).addTo(this.map).on('click', function(e) {
                _this.$store.commit('changeAbout', bus);
                if (!_this.currentRoute.hasOwnProperty(bus.name)) {
                    _this.$http.get('/get_route', { params: { route: bus['type'] + '_' + bus['name'] } }).then(response => {
                        _this.$lodash.each(response.data, function(route) {
                            if (route.length) {
                                let myPolyline = L.polyline(
                                    route, {
                                        color: "#ff0000",
                                        weight: 4,
                                        opacity: 0.5,
                                        interactive: false
                                    });
                                _this.currentRoute[bus.name] ? _this.currentRoute[bus.name].push(myPolyline) : _this.currentRoute[bus.name] = [myPolyline];
                            };
                        });
                        _this.addRouteInMap(bus);
                    });
                } else {
                    if (_this.activeRoute !== bus.name) _this.addRouteInMap(bus);
                };
            });
            return myPlacemark;
        },
        moveBus() {
            Object.keys(this.coordinates).forEach((key) => {
                this.coordinates[key].forEach((bus) => {
                    if (bus["prev"] == undefined) return false;
                    try {
                        let q,
                            marker = this.currentMarkers[bus["type"] + "_" + bus["name"] + "_" + bus["num"]],
                            curLat = parseFloat(marker.getLatLng().lat),
                            curLon = parseFloat(marker.getLatLng().lng),
                            targetLat = bus["lat"].replace(/^(\d\d)/, '$1.'),
                            targetLon = bus["lon"].replace(/^(\d\d)/, '$1.'),
                            prevLat = bus["prev"]["lat"].replace(/^(\d\d)/, '$1.'),
                            prevLon = bus["prev"]["lon"].replace(/^(\d\d)/, '$1.'),
                            distCur = Math.sqrt(Math.pow(targetLat - curLat, 2) + Math.pow(targetLon - curLon, 2)),
                            distPrev = Math.sqrt(Math.pow(targetLat - prevLat, 2) + Math.pow(targetLon - prevLon, 2));
                        if (bus['angle'] != marker.options.angle) {
                            marker.setIcon(this.changeDivIcon(bus));
                            marker.options.angle = bus['angle'];
                        };
                        if (distPrev > distCur) {
                            q = distPrev / distCur
                        } else {
                            q = distCur / distPrev
                        };
                        let k = 20 / q

                        let dlat = (targetLat - curLat) / k
                        let dlon = (targetLon - curLon) / k

                        let newLat = curLat + dlat;
                        let newLon = curLon + dlon;

                        let distNew = Math.sqrt(Math.pow(targetLat - newLat, 2) + Math.pow(targetLon - newLon, 2));
                        if (distNew < distCur) {
                            if (distNew) marker.setLatLng([newLat, newLon]);
                        } else {
                            marker.setLatLng([targetLat, targetLon]);
                        };
                    } catch (e) {
                        console.log('====== Ошибка передвижения маркера', e, bus);
                    };
                })
            })
        },
        createChannels() {
            const t = this
            this.subscriptions.forEach((sub) => {
                t.$cable._addChannel(sub, {
                    rejected() {
                        console.log('rejected to connect to' + sub)
                    },
                    received(data) {
                        this.coordinates[sub] = data
                        this.createMarkers(data, '')
                    },
                }, t)
            })
        }
    }
}
</script>
<style lang="scss">
#map {
    width: 100%;
    height: 100%;

    .marker {
        text-align: center;
        width: 60px;
        height: 60px;
        padding: 1px;
        top: -10px;
        left: -10px;
        position: absolute;
        box-sizing: border-box;

        .marker-row {
            width: 45px;
            height: 45px;
            top: -8px;
            left: -8px;
            position: absolute;
            z-index: -999;
        }

        .marker-body {
            top: 10px;
            text-align: center;
            padding-top: 5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: white;
        }
    }
}
</style>